import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { palette } from "../../_variables"
import { respondTo } from "../../_respondTo"
import { FiLock } from "react-icons/fi"

// used to make child routes show as active
const partlyActive = className => ({ isPartiallyCurrent }) => ({
  className: className + (isPartiallyCurrent ? ` active` : ``),
})

const PartlyActiveLink = ({ className, ...rest }) => (
  <Link getProps={partlyActive(className)} {...rest} />
)
//end

const NavItem = styled(PartlyActiveLink)`
  text-decoration: none;
  color: ${palette.primaryContrastStong};
  display: inline-block;
  white-space: nowrap;
  margin: 0;
  transition: all 200ms ease-in;
  position: relative;
  z-index: 6;
  text-align: center;
  width: 80%;
  border-bottom: thin solid;

  text-transform: uppercase;

  padding: 0.1em 1em;

  :hover {
    ::after {
      width: 100%;
    }
  }

  &.active {
    border-color: ${palette.primaryContrastStong};
  }

  hr {
    color: ${palette.primaryContrast};
  }

  ${respondTo.lg`
    border-radius: 2em;
    border-style: solid;
    border-color: ${palette.primary};
    border-width: thin;


    :hover{
      border-color: ${palette.primaryContrastStong};
    }
    
  `}

  &.pfp {
    background-color: ${palette.primaryContrast};
    color: ${palette.primary};
    border-radius: 2em;
    padding: 0 1em;

    :hover {
      color: ${palette.primaryContrastStong};
      background-color: ${palette.primary};
      text-decoration: none;
    }

    ${respondTo.lg`
    background-color: ${palette.secondary};
    color: ${palette.primaryContrast};
    `}
  }

  &.self-serve {
    background-color: ${palette.primaryContrast};
    color: ${palette.primary};
    border-radius: 2em;
    padding: 0 1em;
    :hover {
      color: ${palette.primaryContrastStong};
      background-color: ${palette.secondaryLight};
      text-decoration: none;
    }
    ${respondTo.md`
  background-color: ${palette.secondary};
  color: ${palette.primaryContrast};
  `}
  }
`

const NavbarLinks = () => {
  return (
    <>
      <NavItem to="/about-you" activeClassName="active">
        About You
      </NavItem>

      <NavItem to="/what-we-do" activeClassName="active">
        What We Do
      </NavItem>

      <NavItem as="a" href="https://www.cutterandco.co.uk/" target="_blank">
        Accountancy Services
      </NavItem>

      <NavItem to="/finding-true-wealth" activeClassName="active">
        Blog
      </NavItem>

      <NavItem to="/contact-us" activeClassName="active">
        Contact Us
      </NavItem>

   

      <NavItem as="a" href="https://cutter.gb.pfp.net/" className="pfp">
        <FiLock style={{ marginRight: "0.1em", paddingTop: "0.1em" }} />
        Sign In
      </NavItem>
    </>
  )
}

export default NavbarLinks
