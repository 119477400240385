/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import Footer from "./footer"

import "@fontsource/nunito"
import "./layout.css"
//import LiveChat from "./live-chat/livechat"

import Navbar from "./navbar/navbar"

const Layout = ({ children }) => {
  return (
    <div>
      <Helmet>
        <script
          defer
          src="https://unpkg.com/@tinybirdco/flock.js"
          data-host="https://api.tinybird.co"
          data-token="p.eyJ1IjogImI4YjM4NTdhLWIxNDktNGUxZi04ZDVjLTI0MDRkNDYyYzg3NyIsICJpZCI6ICI2ZjM1NzkxOS1jYzBjLTQxYzctODUzYy02MDUzM2JhZGFmOWQifQ.8OlRxHRrYbU-XY47671hH9odu21Fo9KszkPSJ8vMD9E"
        ></script>
      </Helmet>
      <Navbar />
      <main>{children}</main>
      <Footer></Footer>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
