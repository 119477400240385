import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import logo from "../../images/cutter-and-co-financial-planning-logo.svg"
import logoWhite from "../../images/cutter-and-co-financial-planning-logo-white.svg"
import { respondTo } from "../../_respondTo"

const LogoWrap = styled.div`
  margin: 0;
  width: 24vh; //(433 / 107) * 6vh - ratio of logo * desired height
  min-width: 124px;
  display: flex;
  align-items: center;

  .color {
    display: none;
  }

  ${respondTo.lg`

    padding-right: 2em;


  `}
`

const Logo = () => {
  return (
    <LogoWrap as={Link} to="/">
      <img
        src={logoWhite}
        alt="Cutter &amp; Co Financial Planning logo"
        style={{ margin: "1vh 2em 1vh 0" }}
      ></img>
    </LogoWrap>
  )
}

export default Logo
