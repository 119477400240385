export const breakpoints = {
  xs: "480px",
  sm: "576px",
  md: "768px",
  lg: "1024px",
  xl: "1500px",
}

export const palette = {
  primary: "#3d5da8",
  primaryContrast: "#f5f5f5",
  primaryContrastStong: "#fff",
  primaryContrastLight: "#c7c7c7",

  dark: "#232531",
  darkContrast: "#818181",

  secondary: "#92aba0",
  secondaryLight: "#658375",
}
